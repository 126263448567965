import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";

interface ErrorData {
  errorMessage: string | null;
  occurrenceCount: number;
  lastMessage?: string;
  lastTags?: string;
  lastTimestamp?: string;
}

export default function OscarErrors() {
  const [data, setData] = useState<ErrorData[]>([]);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState(8);
  const [selectedError, setSelectedError] = useState<ErrorData | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchData = async () => {
    setLoading(true);
    const payload = {
      message: {
        data: JSON.stringify({ hours }),
      },
    };

    const options = {
      method: "POST",
      url: `${api_url}logger/errorCounts`,
      data: payload,
    };

    try {
      const response = await axios.request(options);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching error counts:", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours]);

  const handleRowClick = (error: ErrorData) => {
    setSelectedError(error);
  };

  const handleHourChange = (hour: number) => {
    setHours(hour);
  };

  const filteredData = data
    .filter((item) => item.errorMessage && item.errorMessage.trim() !== "")
    .filter(
      (item) =>
        item.errorMessage.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.lastMessage?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.lastTags?.toLowerCase().includes(searchQuery.toLowerCase())
    );

  // Function to format timestamp
  const formatTimestamp = (timestamp: string | undefined) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      <div className="flex justify-between items-center w-full mb-4">
        <div className="flex space-x-2">
          {[1, 3, 5, 8, 24, 48, 72, 168].map((hour) => (
            <button
              key={hour}
              onClick={() => handleHourChange(hour)}
              className={`px-4 py-2 rounded-md ${
                hours === hour ? "bg-green-600 text-white" : "bg-green-200"
              }`}
            >
              {hour}h
            </button>
          ))}
        </div>
        <h1 className="text-3xl font-semibold">Oscar Errors</h1>
        <div className="flex items-center">
          <button
            onClick={fetchData}
            className={`px-4 py-2 mr-4 rounded bg-blue-500 text-white ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Refreshing..." : "Refresh"}
          </button>
          <input
            type="text"
            placeholder="Search Errors"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 pl-4 border border-gray-300 rounded"
          />
        </div>
      </div>
      {/* Table of errors */}
      <div className="w-full h-full overflow-x-auto mt-2">
        <table className="w-full">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th className="py-2">Error Message</th>
              <th className="py-2">Occurrence Count</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowClick(item)}
                  className="cursor-pointer hover:bg-gray-100"
                >
                  <td className="py-2">
                    {item.errorMessage.length > 50
                      ? item.errorMessage.slice(0, 50) + "..."
                      : item.errorMessage}
                  </td>
                  <td className="py-2 text-center">{item.occurrenceCount}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} className="py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {selectedError && (
        <div
          className="mt-4 p-4 border border-gray-300 rounded-md bg-white"
          style={{
            position: "sticky",
            bottom: "0",
            minHeight: "18vh",
            maxHeight: "40vh",
            overflowY: "auto",
          }}
        >
          <h2 className="text-lg font-semibold mb-2">Error Details</h2>
          <div style={{ wordBreak: "break-word" }}>
            <p>
              <span className="font-semibold">Error Message:</span>{" "}
              {selectedError.errorMessage}
            </p>
            <p>
              <span className="font-semibold">Occurrence Count:</span>{" "}
              {selectedError.occurrenceCount}
            </p>
            {/* Metadata Section */}
            <h3 className="text-md font-semibold mt-4 mb-2">Metadata</h3>
            <p>
              <span className="font-semibold">Last Message:</span>{" "}
              {selectedError.lastMessage || "N/A"}
            </p>
            {/* Extract network and instance from lastTags */}
            {(() => {
              let network = "N/A";
              let instance = "N/A";
              if (selectedError.lastTags) {
                try {
                  const tagsObj = JSON.parse(selectedError.lastTags);
                  network = tagsObj.network || "N/A";
                  instance = tagsObj.instance || "N/A";
                } catch (e) {
                  console.error("Error parsing lastTags:", e);
                }
              }
              return (
                <>
                  <p>
                    <span className="font-semibold">Network:</span> {network}
                  </p>
                  <p>
                    <span className="font-semibold">Instance:</span>{" "}
                    {instance.slice(0, 7)}
                  </p>
                </>
              );
            })()}
            <p>
              <span className="font-semibold">Timestamp:</span>{" "}
              {formatTimestamp(selectedError.lastTimestamp)}
            </p>
          </div>
        </div>
      )}
    </Card>
  );
}

import React, { useState, useEffect } from "react";
import { Card } from "@material-tailwind/react";
import axios from "axios";
import { api_url } from "../../index";

interface Incident {
  id: number;
  component: string;
  group_name: string;
  incident_started: string;
  incident_finished: string | null;
  active: boolean;
  channel_id: string;
  message_ts: string;
  created_at: string;
  updated_at: string;
  duration_min: number | null;
}

const ITEMS_PER_PAGE = 20;

// Adjust this as needed. This will define how many page numbers you see at a time.
const PAGE_CHUNK_SIZE = 50;

export default function IncidentsView() {
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState<number>(8);
  const [activePage, setActivePage] = useState(1);
  const [inactivePage, setInactivePage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const hourOptions = [8, 24, 72, 128, "∞"] as const;

  // Track which chunk we are viewing for active/inactive tables
  const [activeChunk, setActiveChunk] = useState(0);
  const [inactiveChunk, setInactiveChunk] = useState(0);

  useEffect(() => {
    fetchIncidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours]);

  useEffect(() => {
    setActivePage(1);
    setInactivePage(1);
    setActiveChunk(0);
    setInactiveChunk(0);
  }, [searchQuery]);

  const fetchIncidents = async () => {
    setLoading(true);
    const url = `${api_url}manager/monitoring/incidents/${hours}`;
    const options = {
      method: "GET",
      url,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
    };

    try {
      const response = await axios.request<Incident[]>(options);
      setIncidents(response.data);
    } catch (error) {
      console.error("Error fetching incidents:", error);
      setIncidents([]);
    } finally {
      setLoading(false);
    }
  };

  const deleteIncident = async (id: number) => {
    setLoading(true);
    const url = `${api_url}manager/monitoring/notification`;
    const options = {
      method: "DELETE",
      url,
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      },
      data: {
        message: { data: JSON.stringify({ id }) },
      },
    };

    try {
      await axios.request(options);
      setIncidents((prevIncidents) =>
        prevIncidents.filter((incident) => incident.id !== id)
      );
    } catch (error) {
      console.error("Error deleting incident:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleHourChange = (value: number | "∞") => {
    if (value === "∞") {
      setHours(9000);
    } else {
      setHours(value);
    }
  };

  const filteredIncidents = incidents.filter((item) => {
    const combined = `${item.component} ${item.group_name}`.toLowerCase();
    return combined.includes(searchQuery.toLowerCase());
  });

  const activeIncidents = filteredIncidents.filter((i) => i.active);
  const inactiveIncidents = filteredIncidents.filter((i) => !i.active);

  const getPageData = (items: Incident[], currentPage: number) => {
    const start = (currentPage - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return items.slice(start, end);
  };

  const totalPages = (items: Incident[]) =>
    Math.ceil(items.length / ITEMS_PER_PAGE);

  const activeData = getPageData(activeIncidents, activePage);
  const inactiveData = getPageData(inactiveIncidents, inactivePage);

  const activeTotalPages = totalPages(activeIncidents);
  const inactiveTotalPages = totalPages(inactiveIncidents);

  const ChunkedPagination = ({
    currentPage,
    totalPages,
    onPageChange,
    currentChunk,
    onChunkChange,
  }: {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    currentChunk: number;
    onChunkChange: (chunk: number) => void;
  }) => {
    if (totalPages <= 1) return null;

    const totalChunks = Math.ceil(totalPages / PAGE_CHUNK_SIZE);

    const chunkStart = currentChunk * PAGE_CHUNK_SIZE + 1;
    const chunkEnd = Math.min(chunkStart + PAGE_CHUNK_SIZE - 1, totalPages);

    const chunkPages = Array.from(
      { length: chunkEnd - chunkStart + 1 },
      (_, i) => chunkStart + i
    );

    return (
      <div className="flex flex-col items-center mt-2 space-y-2">
        {totalChunks > 1 && (
          <div className="flex space-x-2">
            <button
              onClick={() => onChunkChange(Math.max(currentChunk - 1, 0))}
              disabled={currentChunk === 0}
              className="px-2 py-1 bg-gray-300 rounded disabled:opacity-50"
            >
              {"<<"}
            </button>

            {Array.from({ length: totalChunks }, (_, idx) => idx).map((c) => {
              const start = c * PAGE_CHUNK_SIZE + 1;
              const end = Math.min(start + PAGE_CHUNK_SIZE - 1, totalPages);
              return (
                <button
                  key={c}
                  onClick={() => onChunkChange(c)}
                  className={`px-3 py-1 rounded border ${
                    c === currentChunk
                      ? "bg-blue-500 text-white border-blue-500"
                      : "bg-gray-100 text-gray-700 border-gray-300"
                  }`}
                >
                  {start}-{end}
                </button>
              );
            })}

            <button
              onClick={() => onChunkChange(Math.min(currentChunk + 1, totalChunks - 1))}
              disabled={currentChunk === totalChunks - 1}
              className="px-2 py-1 bg-gray-300 rounded disabled:opacity-50"
            >
              {">>"}
            </button>
          </div>
        )}

        <div
          className="flex justify-start space-x-1 overflow-x-auto p-1 border rounded"
          style={{ maxWidth: "calc((100vw * 2.1) / 3)" }}
        >
          {chunkPages.map((page) => (
            <button
              key={page}
              onClick={() => onPageChange(page)}
              className={`flex-shrink-0 px-3 py-1 rounded border ${
                page === currentPage
                  ? "bg-blue-500 text-white border-blue-500"
                  : "bg-gray-100 text-gray-700 border-gray-300"
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const formatDateTime = (isoString: string | null) => {
    if (!isoString) return "-";
    const date = new Date(isoString);
    return date.toLocaleString();
  };

  return (
    <Card className="h-[calc(100vh-2rem)] w-full p-4 shadow-xl overflow-auto">
      {/* Hour selections and header */}
      <div className="flex justify-between items-center w-full mb-4">
        <div className="flex space-x-2">
          {hourOptions.map((option) => {
            const label = option === "∞" ? "∞" : `${option}h`;
            const value = option === "∞" ? 9000 : option;
            return (
              <button
                key={label}
                onClick={() => handleHourChange(option)}
                className={`px-4 py-2 rounded-md ${
                  hours === value ? "bg-green-600 text-white" : "bg-green-200"
                }`}
              >
                {label}
              </button>
            );
          })}
        </div>
        <h1 className="text-3xl font-semibold">Incidents</h1>
        <div className="flex items-center">
          <button
            onClick={fetchIncidents}
            className={`px-4 py-2 mr-4 rounded bg-blue-500 text-white ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Refreshing..." : "Refresh"}
          </button>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 pl-4 border border-gray-300 rounded"
          />
        </div>
      </div>

      <section className="border rounded bg-green-50 mb-8">
        <h2 className="text-2xl font-semibold p-3 bg-green-100">
          Active Incidents
        </h2>
        <div className="overflow-x-auto p-2">
          <table className="min-w-full border border-gray-200 text-center">
            <thead className="bg-green-100">
              <tr>
                <th className="py-2 px-4 border-b">ID</th>
                <th className="py-2 px-4 border-b">Component</th>
                <th className="py-2 px-4 border-b">Group</th>
                <th className="py-2 px-4 border-b">Incident Started</th>
                <th className="py-2 px-4 border-b">Duration (min)</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {activeData.length > 0 ? (
                activeData.map((incident) => (
                  <tr key={incident.id} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{incident.id}</td>
                    <td className="py-2 px-4 border-b">{incident.component}</td>
                    <td className="py-2 px-4 border-b">{incident.group_name}</td>
                    <td className="py-2 px-4 border-b">
                      {formatDateTime(incident.incident_started)}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {incident.duration_min ?? "-"}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button
                        onClick={() => deleteIncident(incident.id)}
                        className="px-2 py-1 bg-red-500 text-white rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="py-2 px-4 text-center">
                    No Active Incidents Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ChunkedPagination
            currentPage={activePage}
            totalPages={activeTotalPages}
            onPageChange={(page) => setActivePage(page)}
            currentChunk={activeChunk}
            onChunkChange={(chunk) => {
              setActiveChunk(chunk);
              const pageStart = chunk * PAGE_CHUNK_SIZE + 1;
              setActivePage(pageStart);
            }}
          />
        </div>
      </section>

      <section className="border rounded bg-green-50">
        <h2 className="text-2xl font-semibold p-3 bg-green-100">
          Non-Active Incidents
        </h2>
        <div className="overflow-x-auto p-2">
          <table className="min-w-full border border-gray-200 text-center">
            <thead className="bg-green-100">
              <tr>
                <th className="py-2 px-4 border-b">ID</th>
                <th className="py-2 px-4 border-b">Component</th>
                <th className="py-2 px-4 border-b">Group</th>
                <th className="py-2 px-4 border-b">Incident Started</th>
                <th className="py-2 px-4 border-b">Incident Finished</th>
                <th className="py-2 px-4 border-b">Duration (min)</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {inactiveData.length > 0 ? (
                inactiveData.map((incident) => (
                  <tr key={incident.id} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{incident.id}</td>
                    <td className="py-2 px-4 border-b">{incident.component}</td>
                    <td className="py-2 px-4 border-b">{incident.group_name}</td>
                    <td className="py-2 px-4 border-b">
                      {formatDateTime(incident.incident_started)}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {formatDateTime(incident.incident_finished)}
                    </td>
                    <td className="py-2 px-4 border-b text-center">
                      {incident.duration_min ?? "-"}
                    </td>
                    <td className="py-2 px-4 border-b">
                      <button
                        onClick={() => deleteIncident(incident.id)}
                        className="px-2 py-1 bg-red-500 text-white rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="py-2 px-4 text-center">
                    No Non-Active Incidents Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ChunkedPagination
            currentPage={inactivePage}
            totalPages={inactiveTotalPages}
            onPageChange={(page) => setInactivePage(page)}
            currentChunk={inactiveChunk}
            onChunkChange={(chunk) => {
              setInactiveChunk(chunk);
              const pageStart = chunk * PAGE_CHUNK_SIZE + 1;
              setInactivePage(pageStart);
            }}
          />
        </div>
      </section>
    </Card>
  );
}